import React, { Component } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import FaqAccordion from "../faqAccordion/faqAccordion";
import FaqCategory from "../faqCategory/faqCategory";

import "./faqOverview.scss";

class FaqOverview extends Component {
  constructor() {
    super();
    this.hasContent = this.hasContent.bind(this);
  }

  answerHasFilter(item) {
    const { filter } = this.props;

    if (item === undefined || item === null) {
      return false;
    }

    let searchString = "";

    if (typeof item === "string") {
      searchString = item;
    } else if (typeof item === "object") {
      searchString = item.props?.children?.props?.value;
    }

    return searchString?.toLowerCase()?.indexOf(filter?.toLowerCase()) !== -1;
  }

  hasContent() {
    const { hasContent } = this.props;

    return hasContent;
  }

  render() {
    const {
      list,
      filter,
      variant,
    } = this.props;

    const isVariant = variant !== null;

    const tmpList = [];

    list.forEach((item) => {
      if (filter === null || filter === "" || item.category.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
        tmpList.push(item);

        return;
      }

      const tmpAccordionItems = [];
      item.accordionItems.forEach((accordionItem) => {
        if (accordionItem.question.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
          tmpAccordionItems.push(accordionItem);
        } else if (this.answerHasFilter(accordionItem.answer)) {
          tmpAccordionItems.push(accordionItem);
        }
      });

      if (tmpAccordionItems.length > 0) {
        tmpList.push({ accordionItems: tmpAccordionItems, category: item.category });
      }
    });

    return (
      tmpList.length > 0 && (
        <div className={classNames("faq-overview", { [`faq-overview--${variant}`]: isVariant })}>
          {tmpList.map((child, index) => (
            <div key={`faq-overview__item--${index.toString()}`} className="faq-overview__item">
              <FaqCategory category={child.category}>
                <FaqAccordion
                  accordionItems={child.accordionItems}
                  expandable
                  boldTitle
                  variant={variant}
                />
              </FaqCategory>
            </div>
          ))}
        </div>
      )
    );
  }
}

FaqOverview.propTypes = {
  filter: PropTypes.string,
  hasContent: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.shape({
    accordionItems: PropTypes.arrayOf(PropTypes.shape({
      answer: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
        PropTypes.string,
      ]).isRequired,
      question: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.array,
      ]).isRequired,
    })),
    category: PropTypes.string,
  })).isRequired,
  variant: PropTypes.oneOf(["virtual-drive-help"]),
};

FaqOverview.defaultProps = {
  filter: null,
  hasContent: true,
  variant: null,
};

export default FaqOverview;
