import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./faqCategory.scss";

const FaqCategory = ({ children, category }) => (
  <Row className="faq-category">
    <Col xs={12} lg={4} className="faq-category__category">
      {category}
    </Col>
    <Col xs={12} lg={8} className="faq-category__items">
      {children}
    </Col>
  </Row>
);

FaqCategory.propTypes = {
  category: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default FaqCategory;
