import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";

import ContentHeader from "../../components/contentHeader/contentHeader";
import Divider from "../../components/divider/divider";
import MainLayout from "../../components/mainLayout";
import SearchFilter from "../../components/searchFilter/searchFilter";
import SEO from "../../components/seo";
import TextSection from "../../components/textSection/textSection";
import { FaqElement } from "../../images";
import mapKontentItem from "../../utils/mapKontentItem";
import FaqOverviewComponent from "../faq/faqOverviewComponent";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";

const KontentItemFaqPage = ({ data }) => {
  const { page } = data;

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_title.value}
      />

      <div className="container--fullwidth">
        <Container fluid>
          <BreadcrumbGenerator id={data.page.id} />
        </Container>
      </div>

      <ContentHeader
        headline={page.elements.headline.value}
        description={page.elements.lead_text.value}
        media={<FaqElement />}
      />

      <SearchFilter
        variant="faq"
        noSearchContent={
          <Divider isFullWidth />
        }
      >
        <FaqOverviewComponent items={page.elements.faq_groups} />
      </SearchFilter>

      <Divider isFullWidth />

      <TextSection noSpacing headline={page.elements.headline_contact.value} />

      {
        data.page.elements.page_sections.value.map(
          (item) => mapKontentItem(item),
        )
      }

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemFaqPage(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        headline {
          value
        }
        lead_text {
          value
        }
        faq_groups {
          value {
            ...FaqOverviewComponent
          }
        }
        page_sections {
          value {
            internal {
              type
            }
            ...KontentItemEyecatcherContact
          }
        }
        headline_contact {
          value
        }
      }
    }
  }
`;

KontentItemFaqPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemFaqPage;
