import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import FaqOverview from "../../components/faqOverview/faqOverview";
import SimpleRichText from "../text/simpleRichText";

const FaqOverviewComponent = (props) => {
  const {
    items,
    filter,
    hasContent,
    variant,
  } = props;

  if (items.value.length < 0) {
    return null;
  }

  const list = [];

  items.value.forEach((item) => {
    const element = item.elements;
    const tmpAccordionItems = [];

    element.group_items.value.forEach((accordionItem) => {
      tmpAccordionItems.push({
        answer: SimpleRichText({ data: accordionItem.elements.answer }),
        id: accordionItem.id,
        question: accordionItem.elements.question.value,
      });
    });

    list.push({
      accordionItems: tmpAccordionItems,
      category: element.title.value,
    });
  });

  return <FaqOverview variant={variant} filter={filter} hasContent={hasContent} list={list} />;
};

export const query = graphql`
  fragment FaqOverviewComponent on kontent_item_faq_group {
    id
    elements {
      group_items {
        value {
          system {
            type
          }
          ... on kontent_item_faq {
            id
            elements {
              question {
                value
              }
              answer {
                value
                images {
                  width
                  url
                  image_id
                  height
                  description
                }
                links {
                  link_id
                  codename
                  type
                  url_slug
                }
                type
                name
                modular_content {
                  id
                  system {
                    codename
                  }
                  internal {
                    type
                  }
                  ...KontentItemImage
                  ...KontentItemCtaButton
                }
              }
              
            }
          }
        }
      }
      title {
        value
      }
    }
  }
`;

FaqOverviewComponent.propTypes = {
  filter: PropTypes.string,
  hasContent: PropTypes.bool,
  items: PropTypes.oneOfType([PropTypes.object]).isRequired,
  variant: PropTypes.oneOf(["virtual-drive-help"]),
};

FaqOverviewComponent.defaultProps = {
  filter: null,
  hasContent: true,
  variant: null,
};

export default FaqOverviewComponent;
